import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-scroll';
import { BarSoftLogo } from '../icons';

AOS.init({
  offset: 200,
  duration: 800,
  easing: 'ease-in-out-sine',
  delay: 200,
  mirror: true,
});
function Header(props) {
  const { imageSource, title, description, /*lightImage,*/ haveDemo } = props;
  return (
    <div className='app-header' id='header-page' data-aos='zoom-out'>
      <div className='welcome-root'>
        <div className='col col-left'>
          {/* {props.theme === "dark" ? <img className='header-image' src={imageSource} /> : <img className='header-image' src={lightImage} />} */}

          <img className='header-image' src={imageSource} alt='headerImage' />
        </div>
        <div className='col col-right'>
          <div className='logo-on-mobile'>
            <BarSoftLogo />
          </div>
          <h1>{title} </h1>

          <div className='welcome-text'>{description}</div>
          <div className='row'>
            {haveDemo ? (
              <Link to='download' spy={true} smooth={true} duration={500}>
                <div className='btn'>Letöltés</div>
              </Link>
            ) : (
              <></>
            )}
            <Link to='contact-form' spy={true} smooth={true} duration={500}>
              <div className='outlined-btn'>Kapcsolat</div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Header;

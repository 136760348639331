import AOS from 'aos';
import 'aos/dist/aos.css';

// AOS.init()

AOS.init({
  offset: 200,
  duration: 800,
  easing: 'ease-in-out-sine',
  delay: 200,
  mirror: true,
});

function ImageContent({ title, description, imageSource, design, imageSide, lightModeImage, theme }) {
  return (
    <div className='image-section'>
      {design}
      {(() => {
        switch (imageSide) {
          case 'left':
            return (
              <>
                <div className='section-left'>{lightModeImage ? theme === 'light' ? <img src={lightModeImage} className='section-image' /> : <img src={imageSource} className='section-image' /> : <img src={imageSource} className='section-image' />}</div>
                <div className='section-right '>
                  <div className='text-section'>
                    <h1 className='section-label'>{title}</h1>
                    {description}
                  </div>
                </div>
              </>
            );
          case 'right':
            return (
              <>
                <div className='section-left image-on-right-text'>
                  <div className='text-section'>
                    <h1 className='section-label'>{title}</h1>
                    <p>{description}</p>
                  </div>
                </div>
                <div className='section-right'>{lightModeImage ? theme === 'light' ? <img src={lightModeImage} className='section-image' /> : <img src={imageSource} className='section-image' /> : <img src={imageSource} className='section-image' />}</div>
              </>
            );
        }
      })()}
    </div>
  );
}
export default ImageContent;

import Footer from '../components/Footer';
import Header from '../components/Header';
import NavBar from '../components/NavBar';
import ImageContent from '../components/Sections/ImageContent';
import TextSection from '../components/Sections/TextSection';


function Vip() {
  return (
    <main className="main-container" id="main-container">
      <NavBar />
      <Header
        title="BarSoft VIP"
        description={<p>Gyűjtsön vendégeket, épitsen adatbázist, legyenek hűséges vásárlói!</p>}

        imageSource="assets/pos.png"
      />


      <ImageContent
        title="A VIP adta lehetőségek"
        description=
        {<div>
          <p>VIP hűségkártya rendszerünk segitségével létrehozhat kedvezmény szinteket, kategóriákat, a termék adatbázisához tételenként hozzácsatolhat százalékos, vagy fix áras kedvezményt, beállithat vásárlás utáni jóváirt pontokat.</p>
        </div>}
        imageSource="assets/pos.png"
        imageSide={"left"}

      />

      <ImageContent
        title="Szabályozás"
        description={<p>Beállthatja, hogy vendégei a pontokat miként vásárolhatják le és mire, adatokat gyűjthet vásárlási szokásaikról, küldhet nekik marketing üzeneteket e-mailen, vagy ha rendelkezik BrandApp-al, akkor push üzeneteken keresztül is.</p>}
        imageSource="assets/pos.png"
        imageSide={"right"}

      />

      <ImageContent
        title="Kezelés"
        description=
        {<div>
          <p>Mindent egy helyen tud majd kezelni, hiszen IPanel felületünk pont azért lett fejlesztve, hogy vállalkozásának teljes háttér managementjét egy helyen tudja majd kezelni!</p>

        </div>}
        imageSource="assets/pos.png"
        imageSide={"left"}


      />
      <TextSection
        dividerText="Speciális vendégek"
        smallTitle="Törzsvendégek"
        title="Vannak olyan vendégei akiknek külön bánásmód jár?"
        description="VIP rendszerünk segítségével akár manuálisan is adhat virtuális ügyfélkártyáikra pontot, amit teljes egészében levásárolhatnak!"
      />
      <Footer />


    </main>
  );
}

export default Vip;

import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-scroll';

AOS.init({
  offset: 200,
  duration: 800,
  easing: 'ease-in-out-sine',
  delay: 200,
  mirror: true,
});
function NtakHeaderNew(props) {
  const { firstText, firstTextHightlighted, titleText, description, darkImage, lightImage, theme, haveDemo } = props;
  return (
    <div className='ntak-new-app-header' id='header-page' data-aos='zoom-out'>
      {theme === 'dark' ? <img className='app-header-bg' src={darkImage} /> : <img className='app-header-bg' src={lightImage} />}

      <div className='content'>
        <div className='first-line'>
          <span>{firstText}</span>
          <span> {firstTextHightlighted}</span>
        </div>

        <h1>{titleText}</h1>
        <div className='welcome-text'>{description}</div>

        <div className='row'>
          {haveDemo ? (
            <Link to='download' spy={true} smooth={true} duration={500}>
              <div className='btn'>Letöltés</div>
            </Link>
          ) : (
            <></>
          )}
          <Link to='contact-form' spy={true} smooth={true} duration={500}>
            <div className='btn'>Részletek</div>
          </Link>
        </div>
      </div>
    </div>
  );
}
export default NtakHeaderNew;

import { ArrowSecond, CircleDesign } from '../../icons';
import Card from './Cards';

function MultipleCards(props) {

    const { test, showDetailsButton, title, desc, page } = props
    return (

        <div className={"multiple-card-section " + test} id="multiple-card-section">
            <div className='design-object'>
                <ArrowSecond />

            </div>
            <div className='design-object-left'>
                <CircleDesign />

            </div>
            {/* <div className='text-divider'>
                <div className='divider'></div>
                <div className='text-in-divider'>Teljeskörű megoldások</div>
                <div className='divider'></div>
            </div> */}


            {(() => {
                switch (page) {
                    case 'home':
                        return (<><div className='section'>
                            <div className='section-left'>
                                <div className='text-section'>
                                    {title}
                                    {desc}
                                </div>
                            </div>
                            <div className='section-right '><div className='first-column section-flexbox'>
                                <Card
                                    cardHeader="POS rendszer a gyors eladás érdekében"
                                    cardText="Használja fel akár saját hardveres eszközeit, innovatív rendszerünk segítségével a gyors eladás lehetővé teszi a gyors kiszolgálást is. "
                                    navTo="/pos"
                                    detailsClass="pos-details"
                                >
                                </Card>

                                <Card
                                    cardHeader="Asztalhoz rendelés"
                                    cardText="Szeretne előrendelést fogadni? Esetleg eszébe jutott már, hogy milyen jó lenne, ha asztalhoz tudnának rendelni a vendégek online fizetéssel? Nálunk ez teljes mértékben kivitelezhető BarSoft BrandApp segítségével!"
                                    navTo="/brandapp"
                                    detailsClass="brandapp-details"

                                >
                                </Card>

                            </div>
                                <div className='second-column section-flexbox'>
                                    <Card
                                        cardHeader="VIP - Hűségpontok / Vendégadatbázis építés"
                                        cardText="VIP modulunk segítségével magához láncolhatja hűséges vásárlóit, nyújthat nekik kedvezményt, adhat nekik pontokat amiket később levásárolhatnak. Adjon plusz szolgáltatást vendégei számára, tűnjön ki a tömegből VIP modulunk segítségével"
                                        navTo="/brandapp"
                                        detailsClass="brandapp-details"

                                    >
                                    </Card>
                                    <Card
                                        cardHeader="KIOSK - Az automata rendelésfelvevő"
                                        cardText="A BarSoft KIOSK olyan megoldást nyújt, mint amit a nagy gyorsétterem láncokban is használhatunk, csak sokkal jobb! A letisztult kezelőfelület, egyszerű, gyors rendelést biztosít vendégei számára, bankkártyás vagy készpénzes fizetési lehetőséggel! "
                                        navTo="/kiosk"
                                        detailsClass="kiosk-details"

                                    >
                                    </Card>
                                </div> </div>
                        </div></>)
                    case 'pos':
                        return (<><div className='section'>
                            <div className='section-left'>
                                <div className='text-section'>
                                    <h1 className='section-label'>{title}</h1>
                                    <p className='section-text'>{desc}</p>
                                </div>
                            </div>
                            <div className='section-right '>
                                <div className='first-column section-flexbox'>
                                    <Card
                                        cardHeader="Szeretne a termékekhez módosítókat hozzáadni?"
                                        cardText="Rendszerünkkel ez természetesen lehetséges, iPanel felületünkön tömegesen, vagy POS felületünkön is akár! Vigyen fel allergén módosítókat, adjon hozzá külön kiszereléseket és kezelje az eladást rendszerezetten!"
                                        showDetailsButton={showDetailsButton}
                                    >
                                    </Card>

                                    <Card
                                        cardHeader="Szeretne kedvezményt adni vendégeinek, valamilyen akció keretein belül? "
                                        cardText="Szerkesszen kedvezmény mértékeket és adja hozzá a teljes rendeléshez, vagy termékenként külön-külön!"
                                        showDetailsButton={showDetailsButton}
                                    >
                                    </Card>

                                </div>
                                <div className='second-column section-flexbox'>
                                    <Card
                                        cardHeader="Elvitelre is értékesít és aggódik azon, hogy hogyan fogja ezt lekezelni az éttermi szoftverrel?"
                                        cardText="A BarSoft erre is nyújt megoldást, hiszen kiválaszthatja a kosár nézetben a teljes kosár tartalomra, vagy akár termékenként külön-külön is, hogy helyben fogyasztásról van szó, vagy elviteles fogyasztásra!"
                                        showDetailsButton={showDetailsButton}

                                    >
                                    </Card>
                                    <Card
                                        cardHeader="Szeretne az elvitelért plusz díjat felszámolni?"
                                        cardText="Megteheti ezt termékenként, vagy akár egyszeri díjként is! Szoftverünk segítségével az ÁFA tartományt ilyen esetekben automatikusan tudja kezelni, csak egyszer kell konfigurálni!"

                                        showDetailsButton={showDetailsButton}

                                    >
                                    </Card>
                                </div>

                            </div>
                        </div></>)
                }
            })()}

        </div>

    )
}
export default MultipleCards
import axios from 'axios';
import { useState } from "react";
import InputMask from "react-input-mask";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

var axiosReq = axios.create({
    timeout: 15000,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
})

function Contact() {

    const [formData, setFormData] = useState({})
    const [taxError, setTaxError] = useState(false)
    const [companyData, setCompanyData] = useState(null)
    const [taxErrorText, setTaxErrorText] = useState("")
    const handleChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    const getTaxData = (taxNumber) => {

        if (taxNumber == null || taxNumber === "") return null

        let data = {
            taxNumber: "",
            name: "",
            shortName: "",
            address: {
                city: "",
                countryCode: "HU",
                floor: "",
                state: "",
                street: "",
                streetNumber: "",
                telNumber: "",
                zip: ""
            }
        }

        try {
            axiosReq.get(`https://ipanel.okeoke.io/api/v1/common/tax/${taxNumber}`).then(res => {
                if (res.data.success) {
                    data = {
                        taxNumber,
                        name: res.data.data.name,
                        shortName: res.data.data.shortName,
                        address: {
                            city: res.data.data.city,
                            countryCode: "HU",
                            floor: res.data.data.floor,
                            state: "",
                            street: res.data.data.street,
                            streetNumber: res.data.data.streetNumber,
                            telNumber: "",
                            zip: res.data.data.zip
                        }
                    }
                    setCompanyData({ ...companyData, ...data })
                    setTaxError(false)
                    setTaxErrorText("")
                } else {
                    setCompanyData({ ...companyData, ...data })
                    setTaxError(true)
                    setTaxErrorText("Hibás adószám! Kérlek próbáld újra")
                }
            })

        } catch (e) {
            console.log(e)
        }
    }
    const validate = () => {
        let valid = true;

        if (!formData.firstName) valid = false;
        if (!formData.lastName) valid = false;
        if (!formData.email) valid = false;
        if (!formData.telNumber) valid = false;
        if (!formData.locationName) valid = false;

        return valid;
    }
    const sendFormData = () => {

        const valid = validate();


        if (valid) {
            let data = {
                type: "opportunity",
                company_id: 40,
                user_id: 0,
                team_id: 1,
                campaign_id: 0,
                source_id: 0,
                medium_id: 0,
                name: formData.locationName,
                email_from: formData.email,
                contact_name: `${formData.lastName} ${formData.firstName}`,
                mobile: formData.telNumber,
                partner_name: companyData.shortName,
                zip: companyData.zip,
                city: companyData.address.city,
                country_id: 99,
                street: `${companyData.address.street} ${companyData.address.streetNumber}`,
                description: formData.message,
            }

            let template = {
                jsonrpc: "2.0",
                params: {
                    model: "crm.lead",
                    method: "create",
                    args: [data]
                }
            }

            let body = JSON.stringify(template)

            try {
                toast.info('Üzenetküldés folyamatban. Kérlek várj!', {
                    position: "top-center",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    theme: "dark",
                });
                axiosReq.post(`https://ipanel.okeoke.io/api/v1/odoo/form`, body).then(res => {
                    console.log(res);
                    toast.success('Sikeres üzenetküldés. Hamarosan felvesszük önnel a kapcsolatot', {
                        position: "top-center",
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        theme: "dark",
                    });
                })
                setFormData({})

            } catch (e) {
                console.log(e);
                toast.error('Sikertelen üzenetküldés. Próbáld újra később', {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    theme: "dark",
                });
                setFormData({})
            }
        }
        else {
            toast.error('Kérlek tölts ki minden mezőt', {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                theme: "dark",
            });
        }

    }
    return (
        <div className="form-container" id='contact-form'>
            <ToastContainer position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover={false}
                theme="dark" />
            <h1>Lépjen velünk kapcsolatba</h1>
            <p>Kérdése van? Szeretné Ön is használni a rendszereink egyikét? Írjon enkünk még ma és felvesszük önnel a kapcsolatot!</p>
            <div className='formInput'>
                <input name="firstName" placeholder="Keresztnév" value={formData.firstName || ""} onChange={handleChange} />
                <input name="lastName" placeholder="Vezetéknév" value={formData.lastName || ""} onChange={handleChange} />
                <InputMask
                    mask="+36 99 999 9999"
                    name="telNumber"
                    type="text"
                    value={formData.telNumber || ""}
                    onChange={handleChange}
                    placeholder="+36 98 765 4321"
                />
                <input name="email" placeholder="email@barsoft.hu" value={formData.email || ""} onChange={handleChange} />
                <InputMask
                    mask="99999999"
                    name="vat"
                    type="text"
                    onBlur={(e) => getTaxData(e.target.value)}
                    placeholder="45874987"

                />
                <input name="companyName" className='input-mask' value={companyData?.shortName} disabled placeholder='Cég neve' />
                <p className='tax-error'>{taxErrorText}</p>
                {companyData?.address && <input name="companyAddress" placeholder='Cég székhelye' className='input-mask' value={`${companyData.address.zip} ${companyData.address.city} ${companyData.address.street} ${companyData.address.streetNumber}` || ""} disabled />}
                <input name="locationName" placeholder="Vendgélátó hely fantázianeve" value={formData.locationName || ""} onChange={handleChange} />
                <textarea name="message" rows="10" cols="30" className='message' placeholder="Üzenete számunkra (Nem kötelező)" value={formData.message || ""} onChange={handleChange} >

                </textarea>
            </div>
            <div className="btn" onClick={sendFormData} >Küldés</div>

        </div>
    )
}
export default Contact
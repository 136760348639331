import AOS from 'aos';
import 'aos/dist/aos.css';



AOS.init({
    offset: 200,
    duration: 800,
    easing: 'ease-in-out-sine',
    delay: 200,
    mirror: true
});

function PricingCards(props) {
    const { packageDescription, title } = props
    return (
        <div className="pricing-card" data-aos="zoom-out" delay="400">
            <h2>{title}</h2>
            <p className="package-description">{packageDescription}</p>
        </div>
    )
}
function BrandAppUsage(props) {

    const { smallTitle, title, description } = props
    return (

        <div className="cardList-section" >
            <div className='pricing-header'>
                <p className='pricing-small-label'>{smallTitle}</p>
                <h1 className='pricing-label'>{title}</h1>
                <h3 className='pricing-desc'>{description}</h3>
            </div>
            <div className='pricing-list'>
                <PricingCards
                    title="Gyors rendelés"
                    packageDescription="Ha a gyors rendelés a cél, akkor web alapú applikációnk a tökéletes az Ön számára, hiszen nem kell semmit se letölttenie, elég csak egy QR kódot elhelyezni az asztalokon, a többit már az applikáció intézi!"
                />
                <PricingCards
                    title="Előrendelés"
                    packageDescription="Ha előrendeléseket szeretne fogadni, vagy vendég adatbázist építeni, akkor letölthető applikáció lesz a legjobb választás, ami mindig kéznél lesz vendégei számára okostelefonjaikon!"
                />



            </div>

        </div>
    )
}
export default BrandAppUsage


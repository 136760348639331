import Editor from '../components/Editor';
import Footer from '../components/Footer';
import Header from '../components/Header';
import NavBar from '../components/NavBar';
import CardList from '../components/Sections/CardListKiosk';
import ImageContent from '../components/Sections/ImageContent';
import Pricing from '../components/Sections/Pricing';
import TextSection from '../components/Sections/TextSection';
import Contact from './contact';


function Kiosk() {
  return (
    <main className="main-container" id="main-container">
      <NavBar />
      <Header
        title="BarSoft KIOSK"
        description={<>
          <p>A fáradhatatlan pultos, aki megállás nélkül veszi fel a rendelést.</p>

          <p>Használjon olyan innovatív eszközt, ami a rendelésfelvételt teljes mértékben automatizálja vendéglátó egyéségében!</p>
        </>}
        imageSource="assets/kioskHeader.png"
      />
      <CardList
        title="A több hónapos tesztelésnek és a felhasználói gondolkodásmód implementációjának köszönhetően olyan rendszert hoztunk létre, ami:"
        smallTitle="A KIOSK ismertetője"
      />

      <ImageContent
        title="A KIOSK célja"
        description=
        {<>
          <p>Célunk, hogy az éhes / szomjas vásárló, minél hamarabb le tudja adni a rendelését úgy, ahogy azt ő szeretné, egyszerűen, felesleges akadályok nélkül, a lehető legrövidebb úton</p>

          <p>KIOSK rendszerünk teljes mértékben integrált többi eszközünkkel, tehát az Order Manager-en a POS-on is látszik a rendelés, pincérblokkot nyomtatunk a rendelésről, valamint QR kód segítségével amit a rendelés végén mutatunk az ügyfélnek végig tudja követni azt, hogy hol tart a rendelése, milyen fázisban van az elkészültség.</p>
        </>}
        imageSource="assets/kiosk_menu.png"
        imageSide={"left"}

      />

      <ImageContent
        title="Fizetési módok"
        description={<p>Bankkártyás, készpénzes fizetés, helyben fogyasztás, elvitelre rendelés, minden olyan feladatot le tud kezelni rendszerünk, amit a pultos kolléga oldana meg.</p>}
        imageSource="assets/kiosk_payment.png"
        imageSide={"right"}
      />

      <ImageContent
        title="Menü szerkesztése"
        description=
        {<>
          <p>A kínálat IPanel rendszerünkön keresztül könnyen és egyszerűen kezelhető, a termékek elérhetőségének ki és bekapcsolása valós idejű, tehát ha elfogy a kínálatból a megadott termék akkor azonnal ki lehet kapcsolni a rendelési lehetőséget.</p>

          <p>Több üzlet esetén a termék kínálat üzletenként változtatható az alap adatbázis felvitele után.</p>
        </>}
        imageSource="assets/menuEditor.png"
        imageSide={"left"}

      />
      <Editor
        editorType="kiosk"
      />
      <TextSection
        dividerText="Próbálja ki"
        smallTitle="A KIOSK csak Önre vár!"
        title="Tegyen egy próbát!"
        description="Próbálja ki KIOSK megoldásunkat Order Manager és POS szoftverünkkel együtt, és hozza ki a legtöbbet vendéglátó egységéből!"
      />
      <Pricing />

      <Contact />
      <Footer />


    </main>
  );
}

export default Kiosk;

import { NavLink } from 'react-router-dom';
import { Link } from 'react-scroll';
import { BarSoftLogo, Kiosk, LeftArrow, Mobile, Terminal } from '../icons';

function NavBar() {
  let activeClassName = 'selected';

  return (
    <div className='top-bar'>
      <div className='content'>
        <div className='img-container'>
          {/* <img src="assets/logo.png" alt="logo" /> */}
          <BarSoftLogo />
        </div>

        <div className='nav-elements font-large'>
          <div className='nav-element'>
            <NavLink exact={true} to={`/`} className={({ isActive }) => (isActive ? activeClassName : undefined)}>
              <div
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                }}
                className='teszt'>
                Főoldal
              </div>
            </NavLink>
          </div>

          <div className='nav-element'>
            <NavLink exact={true} to='/ntak' className={({ isActive }) => (isActive ? activeClassName : undefined)}>
              <div
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                }}
                className='teszt'>
                NTAK
              </div>
            </NavLink>
          </div>
          <div className='nav-element'>
            <div className='dropdown'>
              Termékeink
              <div className='dropdown-content'>
                <NavLink exact={true} to={`/pos`} className={({ isActive }) => (isActive ? activeClassName : undefined)}>
                  <div
                    onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    }}
                    className='teszt dropdown-products-container'>
                    <div className='dropdown-icons'>
                      <Terminal />
                    </div>
                    <span className='name'>POS</span>
                    <div className='dropdown-icons'>
                      <LeftArrow />
                    </div>
                  </div>
                </NavLink>

                <NavLink exact={true} to={`/brandapp`} className={({ isActive }) => (isActive ? activeClassName : undefined)}>
                  <div
                    onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    }}
                    className='teszt dropdown-products-container'>
                    <div className='dropdown-icons'>
                      <Mobile />
                    </div>
                    <span className='name'>BrandApp</span>
                    <div className='dropdown-icons'>
                      <LeftArrow />
                    </div>
                  </div>
                </NavLink>

                <NavLink exact={true} to={`/kiosk`} className={({ isActive }) => (isActive ? activeClassName : undefined)}>
                  <div
                    onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                    }}
                    className='teszt dropdown-products-container'>
                    <div className='dropdown-icons'>
                      <Kiosk />
                    </div>
                    <span className='name'>KIOSK</span>
                    <div className='dropdown-icons'>
                      <LeftArrow />
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>

          <div className='nav-element'>
            <Link to='pricing' spy={true} smooth={true} duration={500} offset={-300}>
              Árazás
            </Link>
          </div>
          <div className='nav-element'>
            <a href='https://barsoft.hu/home'>iPanel</a>
          </div>
          <Link to='contact-form' spy={true} smooth={true} duration={500} offset={-300}>
            <div className='nav-btn'>Kapcsolat</div>
          </Link>
        </div>
      </div>
    </div>
  );
}
export default NavBar;

import React, { useState } from 'react';
import { SwatchesPicker } from 'react-color';
import { IphoneTop } from '../icons';

function BrandAppEditor() {


    const [bgcolor, setBgcolor] = useState("#1F1D2B");
    const [textColor, setTextColor] = useState("#ffffff");
    const [itemColor, setItemColor] = useState("#252836");
    const [buttonColor, setButtonColor] = useState("#3F9AB7");
    const [font, setFont] = useState("Inter");
    const [errorText, setErrorText] = useState(null)



    const [bgPicker, setBgPicker] = useState(false);
    const [textPicker, setTextPicker] = useState(false);
    const [itemPicker, setItemPicker] = useState(false);
    const [btnPicker, setBtnPicker] = useState(false);


    const handleColorChange = (color, destination) => {
        switch (destination) {
            case 'bg':
                return color.hex === textColor ? setTextColor("#000000") : setBgcolor(color.hex);
            case 'text':
                return color.hex === bgcolor ? setTextColor("#000000") : setTextColor(color.hex);
            case 'item':
                return setItemColor(color.hex);
            case 'btn':
                return setButtonColor(color.hex);
        }
    }
    return (
        <div className="brandapp">
            <div className="left">
                <div className="brandapp-container" style={{ fontFamily: font }}>
                    <div className="screen">
                        <div className='content' style={{ backgroundColor: bgcolor }}>
                            <div className='phone-top-bar'><IphoneTop width="100%" height="100%" /></div>
                            <div className='top-menu-bar'>


                            </div>

                            <div className='location-select'>

                                <span style={{ color: textColor }}>Az ön helye - NYITVA</span>
                            </div>

                            <div className='menu'>
                                <div className='category-list'>
                                    <div className='category'><span style={{ color: textColor }}>Kávék</span></div>
                                    <div className='category'><span style={{ color: textColor }}>Sütemények</span></div>
                                    <div className='category'><span style={{ color: textColor }}>Főételek</span></div>
                                </div>

                                <div className='item-list'>
                                    <div className='item'>

                                    </div>
                                </div>

                                <div className='order-btn' style={{ backgroundColor: buttonColor }}>
                                    <span style={{ color: textColor }}>Remdelés leadása és fizetés</span></div>
                            </div>
                            <div className='bottom-bar'> </div>
                        </div>
                        <div className='phone-bottom-bar'></div>
                    </div>
                </div>
            </div>

            <div className='right'>
                <h1>Tervezze meg a saját BrandApp-ját!</h1>
                <span>Válassza ki az önnek megfelelő színeket és nézze meg, hogyan nézne ki az ön SAJÁT KIOSK-od. Ha tetszik amit lát akkor vegye fel velünk a kapcsolatot és designer csapatunk biztosítja , hogy ez még jobban nézzen ki!</span>

                <p>Háttér színe</p>
                <div className='color-select' style={{ position: "relative" }}>

                    <div className='colorPicker' style={{ backgroundColor: bgcolor }} onClick={() => setBgPicker(!bgPicker)}>
                        <span style={bgcolor === "#ffffff" ? { color: "black" } : { color: "white" }}>Kattintson a módosításhoz</span>
                        {bgPicker ? <div className='popover' style={{ position: "absolute" }}><div className='cover' onClick={() => setBgPicker(false)}></div><SwatchesPicker color={bgcolor} onChange={(color) => { handleColorChange(color, 'bg') }} /> </div> : null}
                    </div>
                </div>

                <p>Szöveg színe</p>
                <div className='color-select' style={{ position: "relative" }}>
                    <div className='colorPicker' style={{ backgroundColor: textColor }} onClick={() => setTextPicker(!textPicker)}>
                        <span style={textColor === "#ffffff" ? { color: "black" } : { color: "white" }}>Kattintson a módosításhoz</span>
                        {textPicker ? <div className='popover' style={{ position: "absolute" }}><div className='cover' onClick={() => setTextPicker(false)}></div><SwatchesPicker color={textColor} onChange={(color) => { handleColorChange(color, 'text') }} /> </div> : null}
                    </div>
                </div>

                <p>Gombok színe színe</p>
                <div className='color-select' style={{ position: "relative" }}>
                    <div className='colorPicker' style={{ backgroundColor: buttonColor }} onClick={() => setBtnPicker(!btnPicker)}>
                        <span style={buttonColor === "#ffffff" ? { color: "black" } : { color: "white" }}>Kattintson a módosításhoz</span>
                        {btnPicker ? <div className='popover' style={{ position: "absolute" }}><div className='cover' onClick={() => setBtnPicker(false)}></div><SwatchesPicker color={buttonColor} onChange={(color) => { handleColorChange(color, 'btn') }} /> </div> : null}
                    </div>
                </div>

                <p>Termékek & Kategóriák színe</p>
                <div className='color-select' style={{ position: "relative" }}>
                    <div className='colorPicker' style={{ backgroundColor: itemColor }} onClick={() => setItemPicker(!itemPicker)}>
                        <span style={itemColor === "#ffffff" ? { color: "black" } : { color: "white" }}>Kattintson a módosításhoz</span>

                        {itemPicker ? <div className='popover' style={{ position: "absolute" }}><div className='cover' onClick={() => setItemPicker(false)}></div><SwatchesPicker color={itemColor} onChange={(color) => { handleColorChange(color, 'item') }} /> </div> : null}
                    </div>
                </div>
                <p>Betűtípús</p>
                <div className='color-select wrap' style={{ position: "relative" }}>
                    <div className='colorPicker' style={font === "Inter" ? { backgroundColor: "#3F9AB7", color: "white" } : null} onClick={() => setFont("Inter")}>
                        Inter
                    </div>
                    <div className='colorPicker' style={font === "Roboto" ? { backgroundColor: "#3F9AB7", color: "white" } : null} onClick={() => setFont("Roboto")}>
                        Roboto
                    </div>

                    <div className='colorPicker' style={font === "Open Sans" ? { backgroundColor: "#3F9AB7", color: "white" } : null} onClick={() => setFont("Open Sans")}>
                        Open Sans
                    </div>

                    <div className='colorPicker' style={font === "Jura" ? { backgroundColor: "#3F9AB7", color: "white" } : null} onClick={() => setFont("Jura")}>
                        Jura
                    </div>

                    <div className='colorPicker' style={font === "Mulish" ? { backgroundColor: "#3F9AB7", color: "white" } : null} onClick={() => setFont("Mulish")}>
                        Mulish
                    </div>
                    <div className='colorPicker' style={font === "Montserrat" ? { backgroundColor: "#3F9AB7", color: "white" } : null} onClick={() => setFont("Montserrat")}>
                        Montserrat
                    </div>
                </div>





            </div>
        </div >
    )
}

export default BrandAppEditor
import AOS from 'aos';
import 'aos/dist/aos.css';
import { NavLink } from 'react-router-dom';

// AOS.init()

AOS.init({
  offset: 200,
  duration: 800,
  easing: 'ease-in-out-sine',
  delay: 200,
  mirror: true,
});
function Card(props) {
  const { cardHeader, cardText, navTo, icon, showDetailsButton, detailsClass } = props;
  return (
    <div className='card-box '>
      <h3 className='card-header'>{cardHeader}</h3>
      <p className='card-text'>{cardText}</p>
      <NavLink to={navTo} exact={true}>
        <div
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          }}
          className='teszt'>
          {showDetailsButton === false ? null : <span className={detailsClass}>Részletek ></span>}
        </div>
      </NavLink>
    </div>
  );
}
export default Card;

import AOS from 'aos';
import 'aos/dist/aos.css';


// AOS.init()

AOS.init({
    offset: 200,
    duration: 800,
    easing: 'ease-in-out-sine',
    delay: 200,
    mirror: true
});
function Pricing(props) {

    const { smallTitle, title, description } = props
    return (

        <div className="pricing-section" id='pricing' style={{ paddingBottom: '50px' }}>
            <div className='pricing-header'>
                {/* <p className='pricing-small-label'>ÁRAK HAMAROSAN</p> */}
                <h1 className='pricing-label'>ÁRAK HAMAROSAN</h1>
            </div>
            {/* <div className='pricing-list'>
                <PricingCards
                    packageName="BarSoft Alap"
                    packageDescription={<div><ul>
                        <li>NTAK összeköttetés</li>
                        <li>Termék értékesítés</li>
                        <li>Termék kategória szerkesztés</li>
                        <li>Termék szerkesztés</li>
                        <li>Terminál szám: 1/üzlet/cég</li>
                        <li>ÁFA managment</li>
                        <li>Pénztárgép QR kód működéssel</li>
                        <li>Minden platformra (Windows, Android, iOS)</li>
                        <li>Felhasználók száma: 1</li>
                        <li>Bankkártya terminál integráció</li>
                    </ul></div>}
                    packagePrice="INGYENES" >

                </PricingCards>


            </div>
            <div className='pricing-header'>
                <h1 className='pricing-label'>Rendelhető modulok:</h1>
                <div className="extras">
                    <ExtraPackages
                        packageName="Termék módosítók és kiszerelések"
                        packagePrice="0 Ft / Hó"
                        packageIcon={<Edit />}
                    />

                    <ExtraPackages
                        packageName="Korlátlan statisztika funkció"
                        packagePrice="0 Ft / Hó"
                        packageIcon={<Stat />}
                    />
                    <ExtraPackages
                        packageName="Felhasználók száma: korlátlan"
                        packagePrice="0 Ft / Hó"
                        packageIcon={<Users />}
                    />
                    <ExtraPackages
                        packageName="Plusz terminál licensz"
                        packagePrice="0 Ft / Nap"
                        packageIcon={<Terminal />}
                    />
                    <ExtraPackages
                        packageName="Kedvezménykezelés"
                        packagePrice="0 Ft / Hó"
                        packageIcon={<Discount />}
                    />
                    <ExtraPackages
                        packageName="Support(ügyfélszolgálat)"
                        packagePrice="0 Ft / Hó"
                        packageIcon={<Support />}
                    />
                    <ExtraPackages
                        packageName="Adóügyi integráció / db"
                        packagePrice="0 Ft / Hó"
                        packageIcon={<Nav />}
                    />
                    <ExtraPackages
                        packageName="KDS licensz / db"
                        packagePrice="0 Ft / Nap"
                        packageIcon={<Kds />}
                    />
                    <ExtraPackages
                        packageName="Pincérblokk vagy rendelésblokk nyomtatás"
                        packagePrice="0 Ft / Hó"
                        packageIcon={<OrderBlokk />}
                    />
                    <ExtraPackages
                        packageName="Vevőkijelző (sorszám) modul / db"
                        packagePrice="0 Ft / Nap"
                        packageIcon={<Vevokijelzo />}
                    />
                    <ExtraPackages
                        packageName="QR kódos asztalhoz rendelés"
                        packagePrice="0 Ft / Hó"
                        packageIcon={<Qr />}
                    />

                    <ExtraPackages
                        packageName="IPanel felhős management system"
                        packagePrice="0 Ft / Hó"
                        packageIcon={<Ipanel />}
                    />
                    <ExtraPackages
                        packageName="KIOSK modul"
                        packagePrice="0 Ft / Nap"
                        packageIcon={<Kiosk />}
                    />
                    <ExtraPackages
                        packageName="VIP törzsvásárlói rendszer"
                        packagePrice="0 Ft / Hó"
                        packageIcon={<Vip />}
                    />

                    <ExtraPackages
                        packageName="Franchise modul (több lokáció) / lokáció"
                        packagePrice="0 Ft / Hó"
                        packageIcon={<Franchise />}
                    />

                </div>
                <h1 className='pricing-label'>Egyéb szolgáltatások</h1>
                <div className="extras">
                    <ExtraPackages
                        packageName="Termék management"
                        packagePrice="0 Ft / Hó"
                        packageIcon={<ItemManagement />}
                    />
                    <ExtraPackages
                        packageName="Alapbeállítás"
                        packagePrice="0 Ft / Hó"
                        packageIcon={<DefaultSettings />}
                    />
                    <ExtraPackages
                        packageName="Telepítési díj"
                        packagePrice="0 Ft"
                        packageIcon={<Install />}
                    />
                    <ExtraPackages
                        packageName="Kiszállási díj"
                        packagePrice="0 Ft / Km"
                        packageIcon={<Drive />}
                    />
                    <ExtraPackages
                        packageName="Plusz funkciókért programozási díj"
                        packagePrice="0 Ft / Óra"
                        packageIcon={<PlusFunctions />}
                    />



                </div>
            </div> */}
            {/* <span style={{ marginBottom: '30px' }}>Az árak nem tartalmazzák az ÁFÁ-t.</span> */}

        </div>
    )
}
export default Pricing

function PricingCards(props) {
    const { packageName, packageDescription, packagePrice } = props
    return (
        <div className="pricing-card" data-aos="zoom-in-down">
            <h2 className="package-name">{packageName}</h2>
            <span className="package-description">{packageDescription}</span>
            <h3 className="package-price">{packagePrice}</h3>
        </div>
    )
}

function ExtraPackages(props) {
    const { packageName, packageDescription, packagePrice, packageIcon } = props
    return (
        <div className="extra-card" data-aos="zoom-in-down" delay="400">
            <div className="icon">
                {packageIcon}
            </div>
            <div className="name-and-price">
                <h2 className="extra-name">{packageName}</h2>
                <h3 className="extra-price">{packagePrice}</h3>
            </div>

        </div>
    )
}
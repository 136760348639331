import React from 'react';
import Footer from '../components/Footer';
import HeaderNew from '../components/HeaderNew';
import NavBar from '../components/NavBar';
import ImageContent from '../components/Sections/ImageContent';
import MultipleCards from '../components/Sections/MultipleCards';
import Pricing from '../components/Sections/Pricing';
import PricingCalculator from '../components/Sections/PricingCalculator';
import Contact from './contact';

function Home(props) {
  return (
    <div className='Home'>
      <NavBar />
      <HeaderNew
        firstText='Megérkezett a '
        firstTextHightlighted=' BarSoft!'
        titleText='A vendéglátás új szemszöge'
        description='Vendéglátóipari szoftver, amit úgy építettünk fel, hogy digitális megoldásainkkal megkönnyítsük az értékesítést és a managementet. Kezelje könnyedén vállalkozásának digitális értékesítési felületeit innovatív megoldásaink segítségével!'
        lightImage='assets/homeHeaderLight.png'
        darkImage='assets/homeHeaderDark.png'
        theme={props.theme}
      />
      <MultipleCards
        title={
          <h1 className='section-label'>
            Innovatív éttermi szoftver <span className='highlighted-text'>minden vendéglátós </span>számára
          </h1>
        }
        desc={
          <p className='section-text'>
            Rendszerünket úgy építettük fel, hogy több éves vendéglátásban, marketingben, designban szerzett tapasztalatunkat összeöntöttük egy olyan szoftverbe, ami könnyen kezelhető, multifunkciós, és több modul csatlakoztatható hozzá.
            <br />
            <br />
            Lépjen magasabb szintre vendéglátó egységével és használja a modern digitlási technológia előnyeit, tűnjön ki a tömegből innovatív modszereinkkel!
          </p>
        }
        showDetailsButton={true}
        page={'home'}
      />
      <ImageContent
        title={<span>NTAK megfelelés</span>}
        description='Mi készen állunk a jogszabály változásokra, és azt szeretnénk, hogy éttermi szoftverünk segítségével Önnek semmilyen egyéb teendője ne legyen a szabályok betartásához, mi mindent intézünk a háttérben! Számunkra a legfontosabb, hogy termékei / szolgáltatása értékesítése zökkenőmentes
              legyen, és ne kelljen több gondot magára vennie mint eddig! Tudjon meg többet az NTAK megfeleltetésről! '
        imageSource={'/assets/whatisntak.png'}
        lightModeImage={'/assets/whatisntaklight.png'}
        imageSide={'left'}
        theme={props.theme}
      />

      <ImageContent
        title={
          <span>
            <span className='highlighted-text'>iPanel</span> - a professzionális kezelőfelület
          </span>
        }
        description='Ha komplett rendszerrel szeretne dolgozni, akkor felhő alapú admin felületünk egy igazi segéd lesz szoftverének kezelésében!
            Egy helyen kezelheti összes eszközét, helyszíneit, ügyfeleit, termékeit egy “Real-Time” alapú felhős rendszerben!
            Kérje le eladásainak statisztikáit, kapcsolja ki-be gyorsan termékeit, kapcsoljon ki-be gyorsan új eszközt.
            Innovatív, érthető, könnyen kezelhető. Tudjon meg többet!
            '
        imageSource={'/assets/ipaneldark.png'}
        lightModeImage={'/assets/ipanel.png'}
        imageSide={'right'}
        theme={props.theme}
      />
      <ImageContent
        title='Fizetési és adóügyi integrációink'
        description='Minden szolgáltatásunk teljes mértékben NAV kompatibilis, POS szoftverünk esetében helyszíni fizetéskor tudunk összekötött adóügyi nyomtatót, vagy adóügyi pénztárgépet biztosítani.
            Bankkártyás fizetési integrációnk segítségével a borravaló adás is teljesen interaktív vendégei számára.
            Felejtse el a külön pénztárgép és bankkártya terminál ütést, minimalizálja a hibalehetőségeket, és kérje teljesen integrált, összekötött rendszerünket!
            '
        imageSource={'/assets/adougyi.png'}
        imageSide={'left'}
        theme={props.theme}
      />
      <Pricing
        smallTitle='Árazás'
        title='Előfizetési rendszerünk kimondottan vendéglátósoknak tervezve'
        description='Jól jönne hétvégente, vagy a forgalmasabb napokra egy-egy plusz eszköz? Innovatív szoftverünk segítségével ez megoldható, hiszen egy új eszköz aktiválása csupán pár kattintásba kerül, élvezze a napi elszámolás előnyeit, és használja eszközeit csak akkor, amikor igazán szüksége van rá!'
      />
      <PricingCalculator />
      <Contact />
      <Footer />
    </div>
  );
}

export default Home;

import Footer from '../components/Footer';
import Header from '../components/Header';
import NavBar from '../components/NavBar';
import BrandAppUsage from '../components/Sections/BrandAppUsage';
import CardList from '../components/Sections/CardList';
import ImageContent from '../components/Sections/ImageContent';
import Pricing from '../components/Sections/Pricing';
import TextSection from '../components/Sections/TextSection';
import Contact from './contact';

function BrandApp() {
    return (
        <main className="main-container" id="main-container">
            <NavBar />
            <Header
                title="BarSoft BrandApp"
                description="Mindig is szeretett volna egy saját applikációt amivel kitűnne a tömegből? A BarSoft BrandApp most rendelkezésedre áll!"
                imageSource="assets/brandAppHeader.png"
            />

            <CardList
                title="Az applikáció segítségével:"
                smallTitle="A BrandApp által nyújtott előnyök"
            />
            <TextSection
                dividerText="Az applikáció célja"
                smallTitle="A legjobb vendégélményért"
                title="Az applikáció fő célja az, hogy a vendég sorban állás nélkül minél gyorsabban, minél egyszerűbben eljusson a kínálat végignézésétől egészen a fizetésig."
                description="Vendéglátós tapasztalatunk segítségével úgy alakítottuk ki a vásárlás menetét, hogy az minél egyszerűbb, átláthatóbb és gyorsabb legyen a vendég számára, a többi már csak az Ön munkatársain múlik!"
            />

            <ImageContent
                title="Az iPanel felületünkön egyszerűen feltöltheti kínálatát"
                description={
                    <div>
                        <ul>
                            <li>Kategóriákba sorolva</li>
                            <li>Alkategóriákat létrehozva</li>
                            <li>Szűrhetően akár allergének szerint</li>
                            <li>Módosítózva</li>
                            <li>Upsale funkcióval</li>
                        </ul>
                        <p className='tips'>Pro Tipp: A legjobb felhasználói élményt úgy nyújtja, ha minden termékhez van kép feltöltve, a leírások ki vannak töltve.</p>
                    </div>}
                imageSource="assets/ipanel.png"
                imageSide={"left"}
            />
            <ImageContent
                title="Önre szabva"
                description={
                    <div>
                        <p>Az applikáció teljesen személyre szabható mind színeiben, megjelenítheti vállalkozása logóját és arculatára szabhatja kinézetét.</p>

                        <p className='tips'>Mintha csak ön készítette volna.</p>
                    </div>}
                imageSource="assets/brandApp.png"
                imageSide={"right"}
            />


            {/* <Viva /> */}
            {/* <Editor
                editorType="brandapp"
            /> */}
            <BrandAppUsage
                title="BrandApp több felhasználásra!"
                description="A gyors rendelés a cél? Vagy inkább az, hogy hűséges vásárlót szeretne magához láncolni? Esetleg inkább előrendeléseket fogadna? Minden problémára tudunk megoldást nyújtani"
                smallTitle="A BrandApp által nyújtott előnyök"
            />

            <TextSection
                dividerText="NAV"
                title="BrandApp és a NAV"
                description={<div>
                    Applikációnk logikai rendszerét úgy fejlesztettük, hogy 100%-ban NAV biztos. A vásárlás online vásárlásnak felel meg, és <span style={{ fontWeight: 'bold' }}>számlázz.hu(R) </span>integrációnk segítségével online nyugtát adunk a vendégnek egyenesen e-mail címére!
                    Ha ÁFÁ-s számla igénye lenne, az is teljes mértékben lehetséges, számára is egyszerűen, hiszen elég csak az adószámát beírnia és rendszerünk automatikusan kitölti neki a többi adatot!
                </div>}

            />
            <ImageContent
                title="Rendeléskövetés"
                description={
                    <div>
                        <p>Vendégei számára lehetővé tesszük azt, hogy az applikáción keresztül a megrendelt tételek elkészülési útját végig tudja majd követni.</p>

                        <p className='tips'>Jelölje be Order Managerünk (KDS) segítségével mennyi idő míg elkészül a rendelés, nyomjon rá hogy kész, majd arra, hogy átadva, ezekről a stádiumokról végig kap majd visszajelzést a vendég!</p>
                    </div>}
                imageSource="assets/orderTracking.png"
                imageSide={"right"}
            />
            <Pricing />

            <Contact />
            <Footer />


        </main>
    );
}

export default BrandApp;

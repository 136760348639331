import { useNavigate } from "react-router-dom";
import { BarSoftLogo } from "../icons";

function Footer() {

    const navigate = useNavigate();


    const handleOnClick = (value) => {
        navigate(value);

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    return (
        <div className="footer">
            <div className="footer-card" >
                <img className="bg" src="/assets/footer-card-bg.png" />
                <img className="filled-bg" src="/assets/footer-card-filled-bg.png" />
                <h1 className="footer-text">KÉSZEN ÁLL, HOGY VELÜNK NÖVELJE ÜZLETÉT?</h1>

            </div>
            <div className="footer-table">
                <div className="footer-row">
                    {/* <img className="footer-logo" src="./assets/logo.png"></img> */}
                    <BarSoftLogo />

                </div>
                <div className="footer-row menu-table">
                    <h4>Termékeink</h4>
                    <p onClick={() => { handleOnClick("/pos") }}>POS</p>
                    <p onClick={() => { handleOnClick("/brandapp") }}>BrandApp</p>
                    <p onClick={() => { handleOnClick("/kiosk") }}>KIOSK</p>
                </div>
                <div className="footer-row menu-table">
                    <h4>Nyilatkozatok</h4>
                    <a href="assets/barsoft-adavedelmi-szabalyzat.pdf"><p>Adatvédelmi Nyilatkozat</p></a>
                </div>
                <div className="footer-row" style={{ textAlign: 'center' }}>
                    <p style={{ fontWeight: "bold" }}>BarSoft Zrt.</p>
                    <p>Adószám: 32128538-2-42</p>
                    <p>Cégjegyzékszám: 01 10 142094</p>
                    <p>E-mail cím: info@barsoft.hu</p>
                    <p>Székhely: 1067 Budapest, Csengery utca 39. B. lház. Fsz. 2. ajtó</p>
                </div>
            </div>
        </div>
    )
}
export default Footer
import React from 'react';
import BrandApp from './brandApp';
import Kiosk from './kiosk';

function Editor(props) {

    const { editorType } = props;
    return (
        <div className='editor' id='editor'>
            {(() => {
                switch (editorType) {
                    case 'kiosk':
                        return (
                            <Kiosk />
                        )
                    case 'brandapp':
                        return (
                            <BrandApp />
                        )


                }
            })()}
        </div>
    )
}

export default Editor
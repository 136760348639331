import React, { useState } from 'react';
import { SwatchesPicker } from 'react-color';
import { BarSoftLogo, Cart, X } from '../icons';

function Kiosk() {


    const [bgcolor, setBgcolor] = useState("#1F1D2B");
    const [textColor, setTextColor] = useState("#ffffff");
    const [itemColor, setItemColor] = useState("#252836");
    const [buttonColor, setButtonColor] = useState("#3F9AB7");
    const [font, setFont] = useState("Inter");
    const [errorText, setErrorText] = useState(null)



    const [bgPicker, setBgPicker] = useState(false);
    const [textPicker, setTextPicker] = useState(false);
    const [itemPicker, setItemPicker] = useState(false);
    const [btnPicker, setBtnPicker] = useState(false);


    const handleColorChange = (color, destination) => {
        switch (destination) {
            case 'bg':
                return color.hex === textColor ? setTextColor("#000000") : setBgcolor(color.hex);
            case 'text':
                return color.hex === bgcolor ? setTextColor("#000000") : setTextColor(color.hex);
            case 'item':
                return setItemColor(color.hex);
            case 'btn':
                return setButtonColor(color.hex);
        }
    }
    return (
        <div className="kiosk">
            <div className="left">
                <div className="kiosk-container" style={{ fontFamily: font }}>
                    <div className="kiosk-panel">
                        <div className="screen" style={{ background: bgcolor }}>
                            <div className="header"  >
                                <h2 style={{ color: textColor }}>AZ ÖN BRANDJE</h2>
                            </div>

                            <div className="content">
                                <div className="cart-line">
                                    <Cart color={textColor} height="50%" />
                                    <div className="cart-sum">
                                        <p style={{ color: textColor }}>Összesen</p>
                                        <p className='price' style={{ color: textColor }}>10 590 Ft</p>
                                    </div>
                                    <div className='rendelesem' style={{ backgroundColor: 'transparent', color: textColor, borderColor: textColor }}>
                                        <span style={{ color: textColor }}>RENDELÉSEM</span>
                                    </div>
                                    <div className='pay' style={{ backgroundColor: buttonColor }}>
                                        <span style={{ color: textColor }}>FIZETÉS</span>
                                    </div>
                                    <X color={textColor} height="50%" />
                                </div>
                                <div className="menu">
                                    <div className="categoryList">
                                        <div className="category" style={{ backgroundColor: buttonColor }}>
                                            <img src='assets/kiosk/kave.png' />
                                            <span className='category-name' style={{ color: textColor }}>Kávék</span>
                                        </div>

                                        <div className="category" style={{ backgroundColor: itemColor }}>
                                            <img src='assets/kiosk/suti.png' />
                                            <span className='category-name' style={{ color: textColor }}>Sütemények</span>
                                        </div>



                                        <div className="category" style={{ backgroundColor: itemColor }}>
                                            <img src='assets/kiosk/foetel.jpg' />
                                            <span className='category-name' style={{ color: textColor }}>Üdítők</span>
                                        </div>

                                        <div className="category" style={{ backgroundColor: itemColor }}>
                                            <img src='assets/kiosk/udito.png' />
                                            <span className='category-name' style={{ color: textColor }}>Főételek</span>
                                        </div>
                                    </div>
                                    <div className="itemlist">
                                        <div className="item" style={{ backgroundColor: itemColor }}>
                                            <img src='assets/kiosk/americano.jpeg' />
                                            <div className="item-data" >
                                                <p className='item-name' style={{ color: textColor }}>Caffe Americano</p>
                                                <p className='item-desc' style={{ color: textColor }}>Termékének leírása.</p>
                                            </div>

                                            <div className="add">
                                                <div className="add-btn" style={{ backgroundColor: buttonColor }}>
                                                    <span className='item-btn-txt' style={{ color: textColor }}>HOZZÁAD</span>

                                                </div>
                                                <div className="price">
                                                    <span style={{ color: textColor }}>590 Ft</span>
                                                </div>
                                                <div className="extras" style={{ backgroundColor: buttonColor }}>
                                                    <span className='item-btn-txt' style={{ color: textColor }}>EXTRÁK</span>
                                                </div>
                                            </div>


                                        </div>



                                        <div className="item" style={{ backgroundColor: itemColor }}>
                                            <img src='assets/kiosk/latte.png' />
                                            <div className="item-data" >
                                                <p className='item-name' style={{ color: textColor }}>Caffe Latte</p>
                                                <p className='item-desc' style={{ color: textColor }}>Termékének leírása.</p>
                                            </div>

                                            <div className="add">
                                                <div className="add-btn" style={{ backgroundColor: buttonColor }}>
                                                    <span className='category-name' style={{ color: textColor }}>HOZZÁAD</span>

                                                </div>
                                                <div className="price">
                                                    <span style={{ color: textColor }}>590 Ft</span>
                                                </div>
                                                <div className="extras" style={{ backgroundColor: buttonColor }}>
                                                    <span className='category-name' style={{ color: textColor }}>EXTRÁK</span>
                                                </div>
                                            </div>


                                        </div>

                                        <div className="item" style={{ backgroundColor: itemColor }}>
                                            <img src='assets/kiosk/mocha.png' />
                                            <div className="item-data" >
                                                <p className='item-name' style={{ color: textColor }}>Mocha</p>
                                                <p className='item-desc' style={{ color: textColor }}>Termékének leírása.</p>
                                            </div>

                                            <div className="add">
                                                <div className="add-btn" style={{ backgroundColor: buttonColor }}>
                                                    <span className='category-name' style={{ color: textColor }}>HOZZÁAD</span>

                                                </div>
                                                <div className="price">
                                                    <span style={{ color: textColor }}>490 Ft</span>
                                                </div>
                                                <div className="extras" style={{ backgroundColor: buttonColor }}>
                                                    <span className='category-name' style={{ color: textColor }}>EXTRÁK</span>
                                                </div>
                                            </div>


                                        </div>
                                        <div className="item" style={{ backgroundColor: itemColor }}>
                                            <img src='assets/kiosk/whiteMocha.jpg' />
                                            <div className="item-data" >
                                                <p className='item-name' style={{ color: textColor }}>White Mocha</p>
                                                <p className='item-desc' style={{ color: textColor }}>Termékének leírása.</p>
                                            </div>

                                            <div className="add">
                                                <div className="add-btn" style={{ backgroundColor: buttonColor }}>
                                                    <span className='category-name' style={{ color: textColor }}>HOZZÁAD</span>

                                                </div>
                                                <div className="price">
                                                    <span style={{ color: textColor }}>690 Ft</span>
                                                </div>
                                                <div className="extras" style={{ backgroundColor: buttonColor }}>
                                                    <span className='category-name' style={{ color: textColor }}>EXTRÁK</span>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='branding'><BarSoftLogo fill="white" /></div>

                </div>
            </div>

            <div className='right'>
                <h1>Tervezze meg a saját KIOSK-ját!</h1>
                <span>Válassza ki az önnek megfelelő színeket és nézze meg, hogyan nézne ki az ön SAJÁT KIOSK-od. Ha tetszik amit lát akkor vegye fel velünk a kapcsolatot és designer csapatunk biztosítja , hogy ez még jobban nézzen ki!</span>

                <p>Háttér színe</p>
                <div className='color-select' style={{ position: "relative" }}>

                    <div className='colorPicker' style={{ backgroundColor: bgcolor }} onClick={() => setBgPicker(!bgPicker)}>
                        <span style={bgcolor === "#ffffff" ? { color: "black" } : { color: "white" }}>Kattintson a módosításhoz</span>
                        {bgPicker ? <div className='popover' style={{ position: "absolute" }}><div className='cover' onClick={() => setBgPicker(false)}></div><SwatchesPicker color={bgcolor} onChange={(color) => { handleColorChange(color, 'bg') }} /> </div> : null}
                    </div>
                </div>

                <p>Szöveg színe</p>
                <div className='color-select' style={{ position: "relative" }}>
                    <div className='colorPicker' style={{ backgroundColor: textColor }} onClick={() => setTextPicker(!textPicker)}>
                        <span style={textColor === "#ffffff" ? { color: "black" } : { color: "white" }}>Kattintson a módosításhoz</span>
                        {textPicker ? <div className='popover' style={{ position: "absolute" }}><div className='cover' onClick={() => setTextPicker(false)}></div><SwatchesPicker color={textColor} onChange={(color) => { handleColorChange(color, 'text') }} /> </div> : null}
                    </div>
                </div>

                <p>Gombok színe színe</p>
                <div className='color-select' style={{ position: "relative" }}>
                    <div className='colorPicker' style={{ backgroundColor: buttonColor }} onClick={() => setBtnPicker(!btnPicker)}>
                        <span style={buttonColor === "#ffffff" ? { color: "black" } : { color: "white" }}>Kattintson a módosításhoz</span>
                        {btnPicker ? <div className='popover' style={{ position: "absolute" }}><div className='cover' onClick={() => setBtnPicker(false)}></div><SwatchesPicker color={buttonColor} onChange={(color) => { handleColorChange(color, 'btn') }} /> </div> : null}
                    </div>
                </div>

                <p>Termékek & Kategóriák színe</p>
                <div className='color-select' style={{ position: "relative" }}>
                    <div className='colorPicker' style={{ backgroundColor: itemColor }} onClick={() => setItemPicker(!itemPicker)}>
                        <span style={itemColor === "#ffffff" ? { color: "black" } : { color: "white" }}>Kattintson a módosításhoz</span>

                        {itemPicker ? <div className='popover' style={{ position: "absolute" }}><div className='cover' onClick={() => setItemPicker(false)}></div><SwatchesPicker color={itemColor} onChange={(color) => { handleColorChange(color, 'item') }} /> </div> : null}
                    </div>
                </div>
                <p>Betűtípús</p>
                <div className='color-select wrap' style={{ position: "relative" }}>
                    <div className='colorPicker' style={font === "Inter" ? { backgroundColor: "#3F9AB7", color: "white" } : null} onClick={() => setFont("Inter")}>
                        Inter
                    </div>
                    <div className='colorPicker' style={font === "Roboto" ? { backgroundColor: "#3F9AB7", color: "white" } : null} onClick={() => setFont("Roboto")}>
                        Roboto
                    </div>

                    <div className='colorPicker' style={font === "Open Sans" ? { backgroundColor: "#3F9AB7", color: "white" } : null} onClick={() => setFont("Open Sans")}>
                        Open Sans
                    </div>

                    <div className='colorPicker' style={font === "Jura" ? { backgroundColor: "#3F9AB7", color: "white" } : null} onClick={() => setFont("Jura")}>
                        Jura
                    </div>

                    <div className='colorPicker' style={font === "Mulish" ? { backgroundColor: "#3F9AB7", color: "white" } : null} onClick={() => setFont("Mulish")}>
                        Mulish
                    </div>
                    <div className='colorPicker' style={font === "Montserrat" ? { backgroundColor: "#3F9AB7", color: "white" } : null} onClick={() => setFont("Montserrat")}>
                        Montserrat
                    </div>
                </div>





            </div>
        </div >
    )
}

export default Kiosk
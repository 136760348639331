import React, { useState } from 'react';

function PricingCalculator() {

    const [price, setPrice] = useState(0)

    const [selectedItems, setSelectedItems] = useState([])


    const handleCheckboxChange = e => {
        if (e.target.checked) {
            setPrice((result) => result + Number(e.target.value));
            setSelectedItems({ ...selectedItems, [e.target.name]: e.target.value })
        }
        else {

            setPrice((result) => result - Number(e.target.value));

        }
    }
    return (
        // <div className='pricing-calculator'>
        //     {/* <h1>Kalkuláld ki a várható költségeid</h1>

        //     <div className='calculator'>
        //         <div className='calculator-items'>

        //             <label className='toggle'>
        //                 <input type="checkbox" className='toggle__input' value={1500} name={"Korlátlan statisztika funkció"} onChange={handleCheckboxChange} />
        //                 <span className="toggle-track">
        //                     <span className="toggle-indicator">

        //                     </span>
        //                 </span>
        //                 <ExtraPackages
        //                     packageName="Korlátlan statisztika funkció"
        //                     packagePrice="1 500 Ft / Hó"
        //                     packageIcon={<Stat />}
        //                 />
        //             </label>

        //             <label className='toggle'>
        //                 <input type="checkbox" className='toggle__input' value={1500} name={"Felhasználók száma korlátlan"} onChange={handleCheckboxChange} />
        //                 <span className="toggle-track">
        //                     <span className="toggle-indicator">

        //                     </span>
        //                 </span>
        //                 <ExtraPackages
        //                     packageName="Felhasználók száma: korlátlan"
        //                     packagePrice="1 500 Ft / Hó"
        //                     packageIcon={<Users />}
        //                 />
        //             </label>
        //             <label className='toggle'>
        //                 <input type="checkbox" className='toggle__input' value={18000} name={"Plusz terminál licensz"} onChange={handleCheckboxChange} />
        //                 <span className="toggle-track">
        //                     <span className="toggle-indicator">

        //                     </span>
        //                 </span>
        //                 <ExtraPackages
        //                     packageName="Plusz terminál licensz"
        //                     packagePrice="600 Ft / Nap"
        //                     packageIcon={<Terminal />}
        //                 />
        //             </label>
        //             <label className='toggle'>
        //                 <input type="checkbox" className='toggle__input' value={1500} name={"Kedvezménykezelés"} onChange={handleCheckboxChange} />
        //                 <span className="toggle-track">
        //                     <span className="toggle-indicator">

        //                     </span>
        //                 </span>
        //                 <ExtraPackages
        //                     packageName="Kedvezménykezelés"
        //                     packagePrice="1 500 Ft / Hó"
        //                     packageIcon={<Discount />}
        //                 />
        //             </label>
        //             <label className='toggle'>
        //                 <input type="checkbox" className='toggle__input' value={10000} name={"Support(ügyfélszolgálat)"} onChange={handleCheckboxChange} />
        //                 <span className="toggle-track">
        //                     <span className="toggle-indicator">

        //                     </span>
        //                 </span>
        //                 <ExtraPackages
        //                     packageName="Support(ügyfélszolgálat)"
        //                     packagePrice="10 000 Ft / Hó"
        //                     packageIcon={<Support />}
        //                 />
        //             </label>
        //             <label className='toggle'>
        //                 <input type="checkbox" className='toggle__input' value={2500} name={"Adóügyi integráció / db"} onChange={handleCheckboxChange} />
        //                 <span className="toggle-track">
        //                     <span className="toggle-indicator">

        //                     </span>
        //                 </span>
        //                 <ExtraPackages
        //                     packageName="Adóügyi integráció / db"
        //                     packagePrice="2 500 Ft / Hó"
        //                     packageIcon={<Nav />}
        //                 />
        //             </label>
        //             <label className='toggle'>
        //                 <input type="checkbox" className='toggle__input' value={9000} name={"KDS licensz / db"} onChange={handleCheckboxChange} />
        //                 <span className="toggle-track">
        //                     <span className="toggle-indicator">

        //                     </span>
        //                 </span>
        //                 <ExtraPackages
        //                     packageName="KDS licensz / db"
        //                     packagePrice="300 Ft / Nap"
        //                     packageIcon={<Kds />}
        //                 />
        //             </label>
        //             <label className='toggle'>
        //                 <input type="checkbox" className='toggle__input' value={1500} name={"Pincérblokk vagy rendelésblokk nyomtatás"} onChange={handleCheckboxChange} />
        //                 <span className="toggle-track">
        //                     <span className="toggle-indicator">

        //                     </span>
        //                 </span>
        //                 <ExtraPackages
        //                     packageName="Pincérblokk vagy rendelésblokk nyomtatás"
        //                     packagePrice="1 500 Ft / Hó"
        //                     packageIcon={<OrderBlokk />}
        //                 />
        //             </label>
        //             <label className='toggle'>
        //                 <input type="checkbox" className='toggle__input' value={9000} name={"Vevőkijelző (sorszám) modul / db"} onChange={handleCheckboxChange} />
        //                 <span className="toggle-track">
        //                     <span className="toggle-indicator">

        //                     </span>
        //                 </span>
        //                 <ExtraPackages
        //                     packageName="Vevőkijelző (sorszám) modul / db"
        //                     packagePrice="300 Ft / Nap"
        //                     packageIcon={<Vevokijelzo />}
        //                 />
        //             </label>
        //             <label className='toggle'>
        //                 <input type="checkbox" className='toggle__input' value={5500} name={"QR kódos asztalhoz rendelés"} onChange={handleCheckboxChange} />
        //                 <span className="toggle-track">
        //                     <span className="toggle-indicator">

        //                     </span>
        //                 </span>
        //                 <ExtraPackages
        //                     packageName="QR kódos asztalhoz rendelés"
        //                     packagePrice="5 500 Ft / Hó"
        //                     packageIcon={<Qr />}
        //                 />
        //             </label>
        //             <label className='toggle'>
        //                 <input type="checkbox" className='toggle__input' value={4500} name={"iPanel felhős management system"} onChange={handleCheckboxChange} />
        //                 <span className="toggle-track">
        //                     <span className="toggle-indicator">

        //                     </span>
        //                 </span>
        //                 <ExtraPackages
        //                     packageName="iPanel felhős management system"
        //                     packagePrice="4 500 Ft / Hó"
        //                     packageIcon={<Ipanel />}
        //                 />
        //             </label>
        //             <label className='toggle'>
        //                 <input type="checkbox" className='toggle__input' value={24000} name={"KIOSK modul"} onChange={handleCheckboxChange} />
        //                 <span className="toggle-track">
        //                     <span className="toggle-indicator">

        //                     </span>
        //                 </span>
        //                 <ExtraPackages
        //                     packageName="KIOSK modul"
        //                     packagePrice="800 Ft / Nap"
        //                     packageIcon={<Kiosk />}
        //                 />
        //             </label>


        //             <label className='toggle'>
        //                 <input type="checkbox" className='toggle__input' value={5500} name={"VIP törzsvásárlói rendszer"} onChange={handleCheckboxChange} />
        //                 <span className="toggle-track">
        //                     <span className="toggle-indicator">

        //                     </span>
        //                 </span>
        //                 <ExtraPackages
        //                     packageName="VIP törzsvásárlói rendszer"
        //                     packagePrice="5 500 Ft / Hó"
        //                     packageIcon={<Vip />}
        //                 />
        //             </label>

        //             <label className='toggle'>
        //                 <input type="checkbox" className='toggle__input' value={4000} name={"Franchise modul (több lokáció) / lokáció "} onChange={handleCheckboxChange} />
        //                 <span className="toggle-track">
        //                     <span className="toggle-indicator">

        //                     </span>
        //                 </span>
        //                 <ExtraPackages
        //                     packageName="Franchise modul (több lokáció) / lokáció"
        //                     packagePrice="4 000 Ft / Hó"
        //                     packageIcon={<Franchise />}
        //                 />
        //             </label>

        //             <label className='toggle'>
        //                 <input type="checkbox" className='toggle__input' value={10000} name={"Alapbeállítás"} onChange={handleCheckboxChange} />
        //                 <span className="toggle-track">
        //                     <span className="toggle-indicator">

        //                     </span>
        //                 </span>
        //                 <ExtraPackages
        //                     packageName="Alapbeállítás"
        //                     packagePrice="10 000 Ft / Hó"
        //                     packageIcon={<DefaultSettings />}
        //                 />
        //             </label>

        //             <label className='toggle'>
        //                 <input type="checkbox" className='toggle__input' value={15000} name={"Termék management"} onChange={handleCheckboxChange} />
        //                 <span className="toggle-track">
        //                     <span className="toggle-indicator">

        //                     </span>
        //                 </span>
        //                 <ExtraPackages
        //                     packageName="Termék management"
        //                     packagePrice="15 000 Ft / Hó"
        //                     packageIcon={<ItemManagement />}
        //                 />
        //             </label>



        //         </div>

        //         <Summary price={price}
        //             selectedItems={selectedItems} />
        //     </div> */}

        // </div>
        <></>
    )
}

export default PricingCalculator
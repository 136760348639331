
function MultipleCards(props) {

    const {smallTitle,title, description, dividerText } = props
    return(
        <div className="text-section">
            <div className='text-divider'>
                <div className='divider'></div>
                <div className='text-in-divider'>{dividerText}</div>
                <div className='divider'></div>
            </div>
            <div className='section'>
                    <div className='text-section'>
                        <p className="section-header">{smallTitle}</p>
                        <h1 className='section-label'>{title}</h1>
                        <p className='section-text'>{description}</p>
                    </div>
            </div>
        </div>
    )
}
export default MultipleCards
import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import './styles/main.scss';

import CookieConsent from 'react-cookie-consent';
import { DarkMode, LightMode } from './components/icons';
import BrandApp from './pages/brandapp';
import Contact from './pages/contact';
import Home from './pages/home';
import Kiosk from './pages/kiosk';
import Ntak from './pages/ntak';
import Pos from './pages/pos';
import Vip from './pages/vip';

function App() {
  const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [theme, setTheme] = useState('light');
  const switchTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
  };
  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);

  useEffect(() => {
    window.addEventListener('scroll', function () {
      console.log(window.scrollY);
      if (window.scrollY > window.innerHeight * 0.85) {
        document.querySelector('.ntak-top-bar').style.background = 'linear-gradient(104.56deg, #3f9ab7 -2.64%, #1c7d6c 99.06%)';
        document.querySelector('.ntak-top-bar').style.transition = 'background-color 0.3s ease';
      } else {
        document.querySelector('.ntak-top-bar').style.background = 'transparent';
        document.querySelector('.ntak-top-bar').style.transition = 'background-color 0.3s ease';
      }
    });
  }, []);

  return (
    <div className='App'>
      <CookieConsent location='bottom' buttonText='Sütik elfogadása' containerClasses='cookie' cookieName='Accept Cookies' style={{ background: '#31768C' }} buttonStyle={{ color: '#24596A', backgroundColor: '#FFFFFF', fontSize: '18px', borderRadius: '5px' }} expires={150}>
        Ez a weboldal sütiket használ, Az oldal használatáoz a sütik engedélyezése kötelező. <span style={{ fontSize: '10px' }}>Részletkért keresd az ÁSZF-t.</span>
      </CookieConsent>
      <button onClick={switchTheme} className='switchtheme'>
        {' '}
        {theme === 'dark' ? <LightMode /> : <DarkMode />} {theme === 'dark' ? 'Világos mód' : 'Sötét mód'}{' '}
      </button>
      <Routes>
        <Route index element={<Home theme={theme} />} />
        <Route path='pos' element={<Pos />} />
        <Route path='brandapp' element={<BrandApp />} />
        <Route path='kiosk' element={<Kiosk />} />
        <Route path='vip' element={<Vip />} />
        <Route path='contact' element={<Contact />} />
        <Route path='ntak' element={<Ntak theme={theme} />} />
      </Routes>
    </div>
  );
}

export default App;

import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-scroll';
import { Arrow, DesignLine } from '../icons';

AOS.init({
  offset: 200,
  duration: 800,
  easing: 'ease-in-out-sine',
  delay: 200,
  mirror: true,
});
function HeaderNew(props) {
  const { firstText, firstTextHightlighted, titleText, description, darkImage, lightImage, theme } = props;
  return (
    <div className='new-app-header' id='header-page' data-aos='zoom-out'>
      {theme === 'dark' ? <img className='app-header-bg' src={darkImage} /> : <img className='app-header-bg' src={lightImage} />}

      <div className='content'>
        <div className='design-object'>
          <DesignLine />
        </div>
        <div className='first-line'>
          <span>{firstText} </span>
          <span className='hightlighted'> {firstTextHightlighted}</span>
          <Arrow />
        </div>

        <h1>{titleText}</h1>
        <div className='welcome-text'>{description}</div>

        <div className='row'>
          <Link to='contact-form' spy={true} smooth={true} duration={500}>
            <div className='btn'>Kapcsolat</div>
          </Link>

          <Link to='pricing' spy={true} smooth={true} duration={500}>
            <div className='outlined-btn'>Árazás</div>
          </Link>
        </div>
      </div>
    </div>
  );
}
export default HeaderNew;

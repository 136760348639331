import AOS from 'aos';
import 'aos/dist/aos.css';



AOS.init({
    offset: 200,
    duration: 800,
    easing: 'ease-in-out-sine',
    delay: 200,
    mirror: true
});
function PricingCards(props) {
    const { packageDescription } = props
    return (
        <div className="pricing-card" data-aos="fade-left">
            <Check />
            <p className="package-description">{packageDescription}</p>
        </div>
    )
}
function Pricing(props) {

    const { smallTitle, title, description } = props
    return (

        <div className="cardList-section">
            <div className='pricing-header'>

                <p className='pricing-small-label'>{smallTitle}</p>
                <h1 className='pricing-label'>{title}</h1>
                <h3 className='pricing-desc'>{description}</h3>
            </div>
            <div className='pricing-list'>
                <PricingCards
                    packageDescription="Lényegretörő"
                />
                <PricingCards
                    packageDescription="Egyszerűen kezelhető"
                />
                <PricingCards
                    packageDescription="Érthető"
                />



            </div>

        </div>
    )
}
export default Pricing

export const Check = (props) => (

    <svg width="35" height="35" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.83341 11.5L7.02091 9.68746C6.86814 9.53468 6.6773 9.4619 6.44841 9.46913C6.21897 9.47579 6.02786 9.55551 5.87508 9.70829C5.7223 9.86107 5.64591 10.0555 5.64591 10.2916C5.64591 10.5277 5.7223 10.7222 5.87508 10.875L8.25008 13.25C8.40286 13.4027 8.5973 13.4791 8.83341 13.4791C9.06953 13.4791 9.26397 13.4027 9.41675 13.25L14.1459 8.52079C14.2987 8.36802 14.3717 8.1769 14.3651 7.94746C14.3579 7.71857 14.2779 7.52774 14.1251 7.37496C13.9723 7.22218 13.7779 7.14579 13.5417 7.14579C13.3056 7.14579 13.1112 7.22218 12.9584 7.37496L8.83341 11.5ZM10.0001 18.3333C8.8473 18.3333 7.76397 18.1144 6.75008 17.6766C5.73619 17.2394 4.85425 16.6458 4.10425 15.8958C3.35425 15.1458 2.76064 14.2638 2.32341 13.25C1.88564 12.2361 1.66675 11.1527 1.66675 9.99996C1.66675 8.84718 1.88564 7.76385 2.32341 6.74996C2.76064 5.73607 3.35425 4.85413 4.10425 4.10413C4.85425 3.35413 5.73619 2.76024 6.75008 2.32246C7.76397 1.88524 8.8473 1.66663 10.0001 1.66663C11.1529 1.66663 12.2362 1.88524 13.2501 2.32246C14.264 2.76024 15.1459 3.35413 15.8959 4.10413C16.6459 4.85413 17.2395 5.73607 17.6767 6.74996C18.1145 7.76385 18.3334 8.84718 18.3334 9.99996C18.3334 11.1527 18.1145 12.2361 17.6767 13.25C17.2395 14.2638 16.6459 15.1458 15.8959 15.8958C15.1459 16.6458 14.264 17.2394 13.2501 17.6766C12.2362 18.1144 11.1529 18.3333 10.0001 18.3333ZM10.0001 16.6666C11.8473 16.6666 13.4204 16.0175 14.7192 14.7191C16.0176 13.4202 16.6667 11.8472 16.6667 9.99996C16.6667 8.15274 16.0176 6.57968 14.7192 5.28079C13.4204 3.98246 11.8473 3.33329 10.0001 3.33329C8.15286 3.33329 6.58008 3.98246 5.28175 5.28079C3.98286 6.57968 3.33341 8.15274 3.33341 9.99996C3.33341 11.8472 3.98286 13.4202 5.28175 14.7191C6.58008 16.0175 8.15286 16.6666 10.0001 16.6666Z" fill="#3F9AB7" />
    </svg>


)